import React, { useState } from "react";
import {NavLink} from "react-router-dom";
import {useAuth} from "../../../auth/provider/AuthProvider";
import SlidingPane from "react-sliding-pane";
type FooterProps = {
    children: React.ReactNode;
}

export const Footer: React.FunctionComponent<FooterProps> = ({children, ...props}): any | null => {

    const {user}: any = useAuth();

    const [isPanelOpen, setIsPanelOpen] = useState(false);

    return (
        <>

            
        </>
    );

};
