import React, {useState, useEffect} from "react";
import {useDispatch} from "react-redux";
import { NavLink } from "react-router-dom";
import { useAuth } from "../../../auth/provider/AuthProvider";
import {setCurrency} from "../../../redux/features/menu/MenuSlice";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";

type HeaderProps = {
    children: React.ReactNode;
}

export const Header:React.FunctionComponent<HeaderProps> = ({ children, ...props}) : any | null => {

  const { user }: any = useAuth();
    
    const dispatch = useDispatch();

    const [isPanelOpen, setIsPanelOpen] = useState(false);

  const [physicalAddress, setPhysicalAddress] = useState('17 Park Street, Harare')

  const [phoneNumber, setPhoneNumber] = useState('+263 (71) 921 6187');

  const [emailAddress, setEmailAddress] = useState('delivery@mamboschicken.co.zw')
  
  const [navMenu, setNavMenu] = useState<any>([])
  
  const dispatchChangeCurrency:Function = (currency:any)=>{
      
      dispatch(setCurrency(currency))
      
  }
  
    useEffect(()=>{
        
        setNavMenu([
            {
                title: 'Home',
                target: '_self',
                slug: 'home',
                href: '/',
                subMenu: []
            },
            {
                title: 'Our Food',
                target: '_self',
                slug: 'menu',
                href: '/menu',
                subMenu: []
            },
            {
                title: 'Menu',
                target: '_self',
                slug: 'menu',
                href: '/DownloadMenu',
                subMenu: []
            },
            {
                title: 'Corporate',
                target: '_self',
                slug: 'corporate',
                href: '/corporate',
                subMenu: []
            },
            {
                title: 'Mambo\'s Experience',
                target: '_self',
                slug: 'mambos-experience',
                href: '/mambos-experience',
                subMenu: []
            },
            {
                title: 'Contact Us',
                target: '_self',
                slug: 'contact-us',
                href: '/contact-us',
                subMenu: []
            },
        ])
        
    },[])
  
  return (
    <>
    
        
    </>
  );
  
};
